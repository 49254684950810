.crm_table td {
  width: 500px;
}
.crm_table {
  width: 96vw;
  overflow: scroll;
}
.crm_table_row th {
  text-transform: uppercase;
  text-align: center;
}
.export_CTA {
  padding: 9px 16px;
  background: #c9f0ff;
  /* text-transform: capitalize; */
  border-radius: 4px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  border: none;
}
.sales_form_users {
  position: absolute;
  top: 60px;
  width: 95%;
  border: 0.1px solid rgb(79, 79, 79);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.admin_box {
  border-radius: 8px;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
  padding: 16px;
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
}

.first-col {
  width: 20% !important;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
}
