@import "./Components/Css/common.css";
@import "./Components/Css/commonRe.css";
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa&family=Mirza&family=Montserrat&family=Noto+Sans&family=Nunito&family=Open+Sans&family=Poppins&family=Ubuntu&display=swap");

@font-face {
  font-family: "AgusSans";
  src: local("AgusSans"),
    url(./Assets/Fonts/AgusSans/AgusSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-light";
  src: local("Nunito-light"),
    url(./Assets/Fonts/nunito/Nunito-Light.ttf) format("truetype");
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.CookieConsent {
  left: auto !important;
  right: 0px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-items: center !important;
}
.cookie_img {
  height: 40px;
}
.CookieConsent button:hover {
  color: #000 !important;
}
