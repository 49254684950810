.founder-verify-main a {
  text-decoration: none;
  color: #4ad7d1;
}

.founder-verify-main a:hover {
  text-decoration: underline;
  color: #4ad7d1;
}

.founder-verify-main ::placeholder {
  color: #9f9f9f;
}

.founder-verify-main .MuiSvgIcon-root {
  color: #4ad7d1;
}

.founder-verify-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem 0;
  padding: 22px 15px;
  border-radius: 15px;
  color: #9f9f9f;
  box-shadow: 0px 0px 6px 0px #4c4b4e;
}

hr.verify-founder-hr {
  width: 100%;
  color: #9f9f9f;
  height: 2px;
}

.verify-founder-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.verify-founder-head-img {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.verify-founder-head-name {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.verify-founder-head-name p {
  margin: 3px;
}

p.verify-founder-company-name {
  font-weight: bold;
  font-size: 2.3rem;
}

p.verify-founder-company-loc {
  font-size: 18px;
  font-weight: 500;
}

a.verify-founder-company-comweb {
  text-decoration: none;
  color: #4ad7d1;
}

a.verify-founder-company-comweb:hover {
  text-decoration: underline;
}

p.verify-founder-company-date {
  font-size: 13px;
}

.verify-founder-head-blank {
  width: 30%;
}

.verify-founder-head-img figure {
  width: 80%;
}

.verify-founder-head-img figure img {
  padding: 10px;
  /* border-radius: 50%; */
  box-shadow: inset -1px -1px 4px 1px #585555, inset 2px 2px 3px 0px #585555;
}

.verify-founder-info {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.verify-founder-infomain {
  width: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 15px;
}

.verify-founder-infomain .MuiSvgIcon-root {
  color: #4ad7d1;
  font-size: 4rem;
}

.verify-founder-doc-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verify-founder-doc {
  width: 58%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verify-founder-infoDocument {
  width: 100%;
  border: 1px solid #9f9f9f;
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.verify-founder-review {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.reviewInput {
  border: none;
  border-bottom: 2px solid #9f9f9f;
  background: transparent;
  outline: none;
  padding: 0 5px;
  font-size: 16px;
  color: white;
  margin: 13px 0;
  width: 60%;
}

.verify-founder-profile {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9f9f9f;
  flex-direction: column;
  padding: 16px;
  border-radius: 13px;
}

.verify-founder-profile-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 13px;
}

.verify-founder-profile-img figure {
  width: 100px;
  border: 1px solid #4ad7d1;
  border-radius: 50%;
  padding: 3px;
  margin: 0;
}

.verify-founder-profile-img figure img {
  border-radius: 50%;
  padding: 1px;
  box-shadow: inset -2px -1px 4px 0px #4ad7d1;
}

.verify-founder-profile-allinfo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.allinfo-details-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.allinfo-details-item a {
  margin-bottom: 1rem;
}

.allinfo-details-item p,
.allinfo-details-item a {
  margin-left: 10px;
}

.verifybtn {
  width: 78%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 967px) {
  .verify-founder-doc {
    width: 45%;
  }

  .verify-founder-profile {
    width: 50%;
  }

  .reviewInput {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .verify-founder-doc-div {
    flex-direction: column;
  }

  .verify-founder-doc {
    width: 80%;
    justify-content: center;
    margin-bottom: 2.5rem;
  }

  .verify-founder-profile {
    width: 80%;
  }
}
@media screen and (max-width: 565px) {
  .founder-verify-main {
    width: 90%;
    margin: 3rem auto;
  }
  .verify-founder-infomain .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
  .verify-founder-infomain {
    font-size: 13px;
  }
  .verifybtn {
    width: 100%;
  }
}
@media screen and (max-width: 445px) {
  .verify-founder-doc {
    width: 100%;
  }
  .verify-founder-profile {
    width: 100%;
  }
  .verify-founder-review div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .founder-verify-main {
    width: 100%;
  }
}
@media screen and (max-width: 370px) {
  .black_CTA_view {
    font-size: 12px;
    padding: 7px 15px;
  }
  .verify-founder-infomain .MuiSvgIcon-root {
    font-size: 1.8rem;
  }
  .verify-founder-infomain {
    font-size: 11px;
  }
}
