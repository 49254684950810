.zr-nav-root {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.zr-nav-root p {
  margin: auto 0;
}
.w-120px {
  width: 120px !important;
}
.w-200px {
  width: 200px !important;
}
.w-100 {
  width: 100%;
}
.bold {
  font-weight: 600;
}
.admin-img {
  width: 40px;
  border: 1px solid grey;
  padding: 0px;
  border-radius: 50%;
  background: white;
}

.zr-nav-root figure {
  margin: 0 13px;
}

.gradient_admin_btn {
  border: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  font-family: Nunito;
  padding: 8px 10px;
  color: white;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(104, 193, 210, 1) 0%,
    rgba(43, 210, 182, 1) 100%
  );
}

.red_btn {
  border: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  font-family: Nunito;
  padding: 8px 10px;
  color: white;
  text-align: center;
  cursor: pointer;
  background: red;
}

.gradient_admin_btn:hover {
  color: black;
}

.main-admin-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;
  position: relative;
  top: 15px;
}

.leftStats {
  width: 30.5%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 70px;
}

.leftReq {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: #a2a5b9;
}

.leftStats .Req,
.leftStats .totalReq {
  width: 48.5%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #404654;
  margin-bottom: 15px;
  border-radius: 10px;
}

.leftStats .totalReq {
  width: 100%;
}

.leftStats .Req h3,
.leftStats .totalReq h3 {
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.totalReq div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.rightStats {
  width: 68%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statsTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.boxed_statics_info {
  width: 100%;
  padding: 1rem;
  border: 1px solid #404654;
  color: #a2a5b9;
  border-radius: 10px;
}

.statsCards {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  flex: 1;
  padding: 10px;
}

.statsCell {
  width: 33.333%;
  border-right: 1px solid #404654;
  border-bottom: 1px solid #404654;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.statsCell:nth-child(3),
.statsCell:nth-child(6),
.statsCell:nth-child(9),
.statsCell:nth-child(12) {
  border-right: none;
}

.statsCell:nth-child(10),
.statsCell:nth-child(11),
.statsCell:nth-child(12) {
  border-bottom: none;
}

.statsCell i.fas {
  font-size: 40px;
  color: #4ad7d1;
  padding: 15px 0;
}

.statsCell h3 {
  font-size: 21px;
  margin: 1px;
}

.statsCell h6 {
  font-size: 13px;
  text-align: center;
}

@media screen and (max-width: 980px) {
  .leftStats {
    width: 40%;
  }

  .rightStats {
    width: 57%;
  }
}

@media screen and (max-width: 765px) {
  .leftStats {
    width: 45%;
  }

  .rightStats {
    width: 50%;
  }

  .statsCards {
    padding: 0;
  }

  .statsCell {
    padding: 0 5px;
  }

  .statsCell i.fas {
    font-size: 30px;
  }
}

@media screen and (max-width: 515px) {
  .admin-container {
    justify-content: center;
    flex-direction: column;
  }

  .leftStats {
    width: 80%;
    position: initial;
    top: 0;
    margin-top: 40px;
  }

  .rightStats {
    width: 80%;
    margin: 20px;
  }

  .statsCell {
    padding: 0 7px;
  }

  .leftStats .Req h3,
  .leftStats .totalReq h3 {
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }

  .gradient_admin_btn {
    font-size: 12px;
  }
}

.auth_root {
  /* background-color: #161616; */
  height: fit-content;
  min-height: 92.2vh;
  padding: 1rem 6rem;
}

.auth_logo {
  width: 120px;
}
.auth_container {
  width: 40%;
  margin-top: 8vh;
}
.auth_header {
  text-align: center;
  margin-bottom: 3vh;
}
.auth_label {
  position: relative;
  width: 100%;
}
.auth_form {
  text-align: center;
}
.auth_input {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 1px solid #404654;
  border-radius: 3px;
  padding: 0 10px;
  color: black;
  font-family: "Nunito";
  margin: 10px 0;
}
.auth_input::placeholder {
  color: black;
}
input::placeholder {
  color: #a2a5b9;
}
textarea .auth_input {
  padding: 10px !important;
}
.forget_pas_img {
  margin-bottom: 5vh;
  width: 80px;
}
.auth_form .multiSelectContainer input {
  color: white !important;
}
.auth_input_password {
  width: 100%;
}
.auth_input_pas_btn {
  position: absolute;
  border: none;
  background-color: transparent;

  bottom: 32%;
  right: 5%;
}
.auth_form .MuiSvgIcon-root {
  fill: #9f9f9f !important;
}
.auth_link_div {
  margin: 20px 0;
  text-align: left;
}
.auth_link {
  text-decoration: none;
  color: #9f9f9f;
  font-family: "Nunito";
  cursor: pointer;
}
.auth_link:hover {
  color: white;
}
.auth_CTA {
  width: 100%;
}
.auth_login_btns {
  justify-content: space-evenly;
}
.auth_login_google {
  width: 100%;
}
/* ---------------Option Page------------------ */
.auth_option_img {
  width: 100%;
}
.auth_option {
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
  cursor: pointer;
}

/* ------------------------welcome page css------------------------*/
.welcome_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.auth_img_cont {
  margin-bottom: 4rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}
.welcome_name {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.register_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register_btn button {
  padding: 12px 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* ------------ thank you page css---------------- */
.thankyou_h {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.smooth-image {
  transition: opacity 1s;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}
.auth_multi_select .searchBox {
  color: #ffffff !important;
}
.auth_multi_select .option .auth_multi_select .optionContainer {
  color: #000 !important;
}
.loginbtns {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.loginbtns a {
  text-decoration: none;
}
.loginbtns .gradient_CTA {
  padding: 12px 17px;
}
.sales_users_table td,
.sales_users_table th {
  border: 1px solid #000 !important;
  padding: 10px !important;
}
.sales_users_table input::placeholder {
  color: "#f7f7f7" !important;
}
.loginbtns .black_CTA {
  padding: 9px 17px;
}
.gradient_CTA {
  border: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  font-family: "Nunito";

  font-display: swap;
  padding: 15px 20px;
  margin-top: 10px;
  color: white !important;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(104, 193, 210, 1) 0%,
    rgba(43, 210, 182, 1) 100%
  );
}
.gradient_CTA:hover {
  color: black;
}

.gradient_CTA_big {
  border: none;
  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;
  font-family: Nunito;

  font-display: swap;
  padding: 15px 20px;
  margin-top: 10px;
  color: white !important;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(104, 193, 210, 1) 0%,
    rgba(43, 210, 182, 1) 100%
  );
}
.gradient_CTA_big:hover {
  color: black;
}
.color-index {
  align-items: center;
  margin: 7px 0;
}
.color-index p {
  width: fit-content;
  margin-bottom: 0;
}
.pointer {
  cursor: pointer;
}
.input-tag {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
@media (max-width: 1150px) {
  .auth_root {
    padding: 1rem 5rem;
  }
  .auth_container {
    width: 60%;
  }
}
@media (max-width: 992px) {
  .auth_root {
    padding: 1rem 4rem;
  }
  .auth_logo {
    width: 130px;
  }
  .auth_container {
    width: 60%;
    margin-top: 15vh;
  }
}

@media (max-width: 768px) {
  .auth_root {
    padding: 1rem 3rem;
  }
  .auth_logo {
    width: 110px;
  }
  .auth_container {
    width: 80%;
    margin-top: 13vh;
  }
  .forget_pas_img {
    width: 60px;
  }
}

@media (max-width: 600px) {
  .auth_logo {
    width: 100px;
  }
  .auth_root {
    padding: 1rem 1rem;
  }
  .auth_container {
    width: 100%;
    margin-top: 8vh;
  }
  .auth_header {
    margin-bottom: 3vh;
  }
  .auth_link_div {
    margin: 10px 0;
    text-align: left;
  }
  .auth_option h5 {
    font-size: 12px;
  }
  .auth_option {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .auth_option_root {
    padding: 20px 0 !important;
  }
}

/* @media (max-width:1150px){
    
}
@media (max-width:992px){
  
}

@media (max-width:768px){
  
}

@media (max-width:600px){
  
    
} */
