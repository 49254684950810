.investorCard .logo-div {
  width: 24%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #9f9f9f;
  margin: 0 5px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.investor-main-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.investor-main-logo figure {
  width: 70px;
  border-radius: 50%;
  padding: 3px;
  box-shadow: 0 0 3px turquoise;
  margin-bottom: 4px;
}
.investor-main-logo figure img {
  border-radius: 50%;
  border: 1px solid turquoise;
}
.investor-main-logo figcaption {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.investor-main-logo figcaption p {
  margin: 0;
}
.investor-main-logo figcaption .founderName {
  font-size: 20px;
}
