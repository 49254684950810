.plan_table {
  width: 100%;
  border-collapse: collapse;
}

.plan_table th,
.plan_table td {
  padding: 10px;
}
.plan_table tbody tr:nth-child(even) {
  background-color: #f6f6f6;
}
.plan_table thead th {
  width: 300px;
  color: white;
  background-color: gray;
  font-size: 18px;
}

.plan_tbody_logo img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.274);
}
