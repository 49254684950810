.h3_poppins_white {
  font-family: Poppins;
  font-display: swap;
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
}
.h3_poppins_white_20 {
  font-family: Poppins;
  font-display: swap;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.zefy_CTA_01 {
  background: #404654;
  border-radius: 8px;
  padding: 8px 16px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_same_cust {
  background: #404654;
  border-radius: 4px;
  padding: 8px 16px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_big_padding {
  background: #404654;
  border-radius: 8px;
  padding: 16px 24px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_wider {
  background: #404654;
  border-radius: 4px;
  padding: 4px 24px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_docs {
  background: #404654;
  border-radius: 4px;
  padding: 16px 32px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_bigger {
  background: #404654;
  border-radius: 6px;
  padding: 12px 36px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 500;
  border: 2px solid transparent;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_img {
  background: transparent;
  border-radius: 6px;
  padding: 8px 12px;
  color: #757575;
  font-family: Nunito;
  font-display: swap;
  border: #757575;
  font-weight: 500;
  border: 1px solid #757575 !important;
  height: fit-content;
  font-size: 10px;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.zefy_CTA_01_me {
  background: #404654;
  border-radius: 8px;
  padding: 3px 20px 8px 20px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
  border: 2px solid transparent;
}
.zefy_CTA_01_black_outline {
  background: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid #000;
  font-weight: 600;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_white_outline {
  background: transparent;
  border-radius: 8px;
  padding: 10px 15px;
  color: #fff;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid #fff;
  font-weight: 600;
}
.zefy_CTA_01_white_outline_2 {
  background: transparent;
  border-radius: 8px;
  padding: 10px 10px;
  color: #fff;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid #fff;
  font-weight: 600;
}
.zefy_CTA_01_outline {
  background: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid rgba(104, 193, 210, 1);
  font-weight: 600;
  height: fit-content;
  font-size: 12px;
}
.zefy_CTA_01_small {
  background: #404654;
  border-radius: 8px;
  padding: 7px 12px;
  color: #ffffff;
  font-family: Nunito;
  font-display: swap;
  border: none;
  font-weight: 600;
}
.zefy_CTA_01_small_outline {
  background: transparent;
  border-radius: 8px;
  padding: 7px 12px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid rgba(104, 193, 210, 1);
  font-weight: 600;
}
.zefy_CTA_01_small_outline2 {
  background: transparent;
  border-radius: 8px;
  padding: 6px 9px;
  color: #000;
  font-family: Nunito;
  font-display: swap;
  border: 2px solid rgba(104, 193, 210, 1);
  font-weight: 600;
}
.aqua2 {
  color: rgba(104, 193, 210, 1);
}
.aqua_gradient {
  background: linear-gradient(90deg, #68c1d2 0%, #2bd2b6 99.99%);
}
.aqua_gradient_text {
  color: #239f99 !important;
}
.row_between {
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  flex-wrap: wrap;
}
.row_align {
  display: flex;
  margin: 0;
  align-items: center;
  flex-wrap: wrap;
}
.font25 {
  font-size: 25px !important;
}
.pink_bg {
  background: rgba(229, 105, 151, 0.1) !important;
}
.yellow_bg {
  background: rgba(251, 199, 64, 0.1);
}
.yellow {
  color: yellow;
}
.light_pink_bg {
  background: rgba(189, 151, 203, 0.1);
}
.green_bg {
  background: rgba(168, 249, 73, 0.1) !important;
}
.white_bg {
  background: #ffffff;
}
.blue_bg {
  background: rgba(104, 193, 210, 0.1) !important;
}
.cyan_bg {
  background: rgba(74, 215, 209, 0.1);
}
.pink_bg_br {
  border: 1px solid rgba(229, 105, 151, 0.5);
  background: #fff;
}
.yellow_bg_br {
  border: 1px solid rgba(251, 199, 64, 1);
  background: #fff;
}
.light_pink_bg_br {
  border: 1px solid rgba(189, 151, 203, 0.5);
  background: #fff;
}
.green_bg_br {
  border: 1px solid rgba(168, 249, 73, 1);
  background: #fff;
}

.blue_bg_br {
  border: 1px solid rgba(104, 193, 210, 1);
  background: #fff;
}
.cyan_bg_br {
  border: 1px solid rgba(74, 215, 209, 0.5) !important;
  background: #fff;
}
.purple_bg_br {
  border: 1px solid rgba(189, 151, 203, 1);
  background: #fff;
}

.pink_bg_br:hover {
  border: 1px solid rgba(229, 105, 151, 0.5);
  background: rgba(229, 105, 151, 0.1);
}
.yellow_bg_br:hover {
  border: 1px solid rgba(251, 199, 64, 1);
  background: rgba(251, 199, 64, 0.1);
}
.light_pink_bg_br:hover {
  border: 1px solid rgba(189, 151, 203, 0.5);
  background: rgba(189, 151, 203, 0.1);
}
.green_bg_br:hover {
  border: 1px solid rgba(168, 249, 73, 1);
  background: rgba(168, 249, 73, 0.01);
}

.blue_bg_br:hover {
  border: 1px solid rgba(104, 193, 210, 1);
  background: rgba(104, 193, 210, 0.1);
}
.cyan_bg_br:hover {
  border: 1px solid rgba(74, 215, 209, 0.5);
  background: rgba(74, 215, 209, 0.1);
}
.purple_bg_br:hover {
  border: 1px solid rgba(189, 151, 203, 1);
  background: rgba(189, 151, 203, 0.1);
}

.dark_pink_bg {
  background: rgba(255, 142, 156, 1) !important;
}
.dark_yellow_bg {
  background: rgba(251, 199, 64, 1) !important;
}

.dark_purple_bg {
  background: rgba(189, 151, 203, 1) !important;
}
.p_nunito_14 {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}
.p_nunito_14_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}
.p_nunito_13_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}
.p_nunito_13_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}
.h1_nunito_20_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
}
.h1_nunito_24_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}
.h1_poppins_24_black {
  font-family: Poppins;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}
.h1_nunito_24_white {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #ffffff;
}
.h1_nunito_16_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}
.h1_nunito_14_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  /* text-transform: uppercase; */

  color: #000000;
}

.p_nunito_14_gre {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #757575;
}
.p_nunito_14_white {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ffffff;
}
.p_nunito_18_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #757575;
}
.p_nunito_18_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}
.p_nunito_14_black {
  font-family: Nunito !important;
  font-display: swap;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  /* or 129% */

  letter-spacing: 0.02em !important;

  color: #000000 !important;
}
.p_nunito_10 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ffffff;
}
.p_nunito_10_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}
.p_nunito_10_red {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ff725e;
}
.p_nunito_10_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}
.p_nunito_16_cust {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff;
}
.no_text_transform {
  text-transform: none !important;
}
.p_nunito_16_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}
.p_nunito_12_blue {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #2bd2b6;
}
.p_nunito_12_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #000000;
}
.p_nunito_20_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 129% */

  letter-spacing: 0.04em;

  color: #000000;
}
.p_poppins_24_black {
  font-family: Poppins;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* or 129% */

  letter-spacing: 0.04em;

  color: #000000;
}
.p_nunito_12_red {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #ff4f4f;
}
.p_nunito_12_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 129% */

  letter-spacing: 0.02em;

  color: #404654;
}

.sq_avatar {
  border-radius: 50% !important;
  height: 32px !important;
  width: 32px !important;
}
.btn_customize {
  padding-top: 6px !important;
  border-radius: 5px;
  align-items: center;
  padding-bottom: 6px !important;
  padding-left: 6px !important;
  padding-right: 12px !important;
  background: linear-gradient(
    90deg,
    rgba(104, 193, 210, 0.1) 0%,
    rgba(43, 210, 182, 0.1) 99.99%
  ) !important;
  height: 38px !important;
  margin-top: 5px !important;
  width: 61px !important;
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 10px;
}

.h1_nunito_16 {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: 0.02em;
  /* 239f99 */
  color: #239f99 !important;
  border-radius: 4px !important;
}

.p_nunito_11 {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  /* or 120% */
  letter-spacing: 0.02em;
  color: #404654;
}

.p_nunito_11_grey {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  /* or 120% */

  letter-spacing: 0.02em;

  color: #757575;
}

.btn_equal_padding {
  border-color: transparent;
  border-radius: 4px;
  padding: 4px;
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  /* identical to box height, or 125% */

  letter-spacing: 0.02em;

  /* 239f99 */

  color: #239f99;
  background: linear-gradient(
    90deg,
    rgba(104, 193, 210, 0.1) 0%,
    rgba(43, 210, 182, 0.1) 99.99%
  );
}
.h1_nunito_25_black {
  font-family: Nunito;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
}
.display_inline {
  display: inline !important;
}
.display_block {
  display: block !important;
}
.no_margin {
  margin: 0px !important;
}
.fw-44 {
  font-weight: 400 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
