.admin-user-activity table {
  padding: 20px;
  margin: auto;
  width: 100%;
}
.admin-user-activity table td {
  padding: 20px 0;
  border: 1px solid #ccc;
  text-align: center;
}
.admin-user-activity table td .MuiAvatar-root {
  margin: 0 auto;
}
.dashboard01_main_box {
  min-height: 100vh;
  position: relative;
  padding-left: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.valuation_filter select {
  min-width: 152px;
  padding: 7px;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 18px;
}

.pagination button.Mui-selected {
  background-color: rgb(255 255 255 / 49%);
}

.dashboard01_tabs {
  min-width: 200px;
  position: relative;
}

.dashboard01_tabs .MuiTab-root {
  font-weight: 400;
  align-items: flex-start;
  text-transform: capitalize;
  font-size: 17px;
  fill: inherit;
  padding-left: 1vw;
}
.dashboard01_tabs .Mui-selected {
  color: #4ad7d1 !important;
}
.dashboard01_tabs .Mui-selected svg {
  fill: #4ad7d1 !important;
}
.dashboard01_tabs .MuiTabs-indicator {
  display: none;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.investor-file {
  background-color: #efefef;
  height: 100%;
  padding: 0 18px;
  font-size: 14px;
  font-family: Nunito;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  min-width: 140px;
}
